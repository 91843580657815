import React, { useEffect, useRef, useState } from 'react';
import Softphone from '7moor-softphone-sdk';
import './SoftphoneComponent.css'; // 引入自定义的CSS文件

const SoftphoneComponent = () => {
    const softphoneRef = useRef(null);
    const [incomingCall, setIncomingCall] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [callHistory, setCallHistory] = useState([]); // 存储通话记录
    const [seatStatus, setSeatStatus] = useState('0'); // 默认状态为“空闲”

    // 初始化软电话
    const initSoftphone = () => {
        console.log('初始化软电话...');
        const softphone = new Softphone({
            accountId: 'N00000062988',
            agentNumber: '8000@sryhq',
            password: 'Bh8D6zrL4z8000',
            loginType: 'sip',
            proxy_url: 'https://pbx-sh-dxali1.7moor.com',
            success: () => {
                console.log('座席初始化成功');
            },
            error: (err) => {
                console.error('座席初始化失败', err);
            }
        });

        softphone.attachEvent({
            success: () => {
                console.log('事件绑定成功');
            },
            message: (res) => {
                if (res && res.type) {
                    console.log('收到事件', res.type, res);
                    handleSoftphoneEvent(res);
                } else {
                    console.error('事件数据不完整或未定义:', res);
                }
            },
            error: (err) => {
                console.error('事件绑定失败', err);
            }
        });

        softphoneRef.current = softphone;
    };

    // 处理软电话事件
    const handleSoftphoneEvent = (res) => {
        const { type, eventData } = res;

        switch (type) {
            case 'belling':
                setIncomingCall(true); // 标记有来电
                break;
            case 'dialing':
                console.log('呼叫中...', type, eventData);
                break;
            case 'innerTalking':
            case 'dialTalking':
                console.log('通话开始');
                // updateCallHistory('呼出', eventData);
                // handleTalkingEvent('呼出',eventData);
                handleTalkingEvent(eventData.ChannelType, eventData)
                setIncomingCall(false); // 来电标记取消
                break;
            case 'hangup':
                console.log('通话结束');
                setIncomingCall(false); // 来电标记取消
                // updateCallHistory('呼入', eventData);
                break;
            case 'peerstate':
                handlePeerStateEvent(eventData); // 处理 peerstate 事件
                break;
            case 'talking': // 呼入电话的录音事件
                // handleTalkingEvent('呼入',eventData);
                handleTalkingEvent(eventData.LinkedChannel.ChannelType, eventData.LinkedChannel)
                break;
            default:
                console.log('未知事件类型:', type);
        }
    };

    // 处理 talking 事件
    const handleTalkingEvent = (type,eventData) => {
        const monitorUrl = eventData?.Data?.MonitorFilename;
        if (monitorUrl) {
            updateCallHistory(type, eventData, monitorUrl);
        }
    };

    // 处理 peerstate 事件
    const handlePeerStateEvent = (eventData) => {
        if (eventData.ChannelStatus === 'Hangup') {
            console.log('用户挂断电话:', eventData);
            // updateCallHistory('呼出', eventData); // 添加呼出记录

        }
        if (eventData.Event === "UserBusy") {
            console.log('设置坐席状态：', eventData.BusyType);
            setSeatStatus(eventData.BusyType);
        }
    };

    // 更新通话记录
    const updateCallHistory = (type, eventData, monitorUrl = '无录音') => {
        const newRecord = {
            type,
            from: eventData.FromCid || eventData.Data.ListenExten,
            to: eventData.FromDid || eventData.Exten,
            time: new Date().toLocaleString(),
            monitor: monitorUrl
        };
        setCallHistory(prevHistory => [newRecord, ...prevHistory]);
    };

    // 接听电话
    const acceptCall = () => {
        if (softphoneRef.current && softphoneRef.current.webrtcApi) {
            softphoneRef.current.webrtcApi.answer({
                success: () => {
                    console.log('成功接听');
                    setIncomingCall(false);
                },
                fail: (err) => {
                    console.error('接听失败', err);
                }
            });
        }
    };

    // 外呼功能
    const makeCall = () => {
        if (softphoneRef.current && softphoneRef.current.callApi) {
            softphoneRef.current.callApi.dialout({
                calleeNumber: phoneNumber,
                success: () => {
                    console.log('外呼成功');
                    // updateCallHistory('呼出', { FromCid: phoneNumber, Data: {} });
                },
                fail: (err) => console.error('外呼失败', err)
            });
        } else {
            console.warn('软电话未初始化');
        }
    };

    // 切换坐席状态
    const changeSeatStatus = (status) => {
        if (softphoneRef.current && softphoneRef.current.agentApi) {
            softphoneRef.current.agentApi.updateAgentStatus({
                statusValue: status,
                success: (res) => {
                    if (res.success) {
                        console.log('坐席状态更新成功:', status);
                        setSeatStatus(status);
                    } else {
                        console.error('坐席状态更新失败');
                    }
                },
                fail: (err) => {
                    console.error('坐席状态更新失败:', err);
                }
            });
        }
    };

    useEffect(() => {
        initSoftphone();
        return () => {
            if (softphoneRef.current) {
                softphoneRef.current = null;
            }
        };
    }, []);

    // 根据状态设置颜色
    const getStatusClass = () => {
        switch (seatStatus) {
            case '0': return 'status-idle'; // 空闲
            case '1': return 'status-busy'; // 忙碌
            case '2': return 'status-break'; // 小休
            default: return '';
        }
    };

    return (
        <div className={`softphone-container ${getStatusClass()}`}>
            <h1>软电话坐席</h1>
            <div className="seat-status">
                <label>切换坐席状态：</label>
                <select value={seatStatus} onChange={(e) => changeSeatStatus(e.target.value)}>
                    <option value="0">空闲</option>
                    <option value="1">忙碌</option>
                    <option value="2">小休</option>
                </select>
            </div>
            <div className="phone-controls">
                <input
                    type="text"
                    placeholder="输入电话号码"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <button onClick={makeCall} disabled={!phoneNumber}>
                    外呼
                </button>
                <button onClick={() => softphoneRef.current.callApi.hangup({
                    success: () => console.log('挂断成功'),
                    fail: (err) => console.error('挂断失败', err)
                })}>
                    挂断
                </button>
                {incomingCall && (
                    <button onClick={acceptCall}>接听电话</button>
                )}
            </div>

            <h2>通话记录</h2>
            <div className="call-history">
                {callHistory.length === 0 ? (
                    <p>暂无通话记录</p>
                ) : (
                    <ul>
                        {callHistory.map((record, index) => (
                            <li key={index}>
                                <span>{record.time}</span> -
                                <span>{record.type}</span> -
                                <span>从 {record.from}</span> -
                                <span>到 {record.to}</span> -
                                <a href={record.monitor} target="_blank" rel="noopener noreferrer">录音</a>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default SoftphoneComponent;