import React from 'react';
import SoftphoneComponent from './Softphone';

function App() {
  return (
      <div className="App">
        <h1>坐席测试</h1>
        <SoftphoneComponent />
      </div>
  );
}

export default App;